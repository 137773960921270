import axios from 'axios';
import qs from 'qs';

export class ApiError extends Error {
    /**
     * @param {string} message
     * @param {object} [response]
     * @param {{method: string, url: string, params: object, headers: object}} [request]
     */
    constructor(message, response, request) {
        super(message);

        /**
         * @type {?Object}
         */
        this.response = response;

        /**
         * @type {?{method: string, url: string, params: Object, headers: Object}}
         */
        this.request = request;
    }
}

export class Api {
    /**
     * @param {string} url
     */
    constructor(url) {
        this.url = url;

        this.axios = axios.create();
    }

    /**
     * @param {String} method
     * @param {String} endpoint Should start with a /
     * @param {object} [data]
     *
     * @return {Promise}
     */
    request(method, endpoint, data) {
        const url = this.url + endpoint;

        // Query string data.
        let queryParams = {};

        // POST body data.
        let requestData = null;

        if (method === 'GET') {
            // For GET requests combine the data with the query params and put it all in the query string.
            queryParams = {
                ...data,
            };
        } else {
            // For POST/PUT/PATCH/etc. requests the default query params go in the query string and the data
            // goes into the request body.
            if (data instanceof FormData) {
                requestData = data;
            } else {
                // If `data` is an object the request will be sent as application/json.
                // So we convert it to a string using the qs package
                // and it will be sent as application/x-www-form-urlencoded
                requestData = qs.stringify(data);
            }
        }

        console.log('[API] Request', method, url); // , jsonParams, headers);

        const requestStartedAt = new Date();
        return this.axios.request({
            method,
            url,

            // `params` are the URL parameters (query string) to be sent with the request
            // Must be a plain object or a URLSearchParams object.
            // Note: If the `url` already contains a query string it will append to it, but it will not overwrite,
            // existing keys - you will end up with duplicates.
            params: queryParams,

            // `data` is the request body for POST/PATCH/etc. requests. For GET requests it does nothing.
            data: requestData,

            responseType: 'json',

            headers: {
                'X-Session-Token': window.session.token,
            },

            timeout: 20000, // 20 seconds
        })
            .then((response) => {
                console.debug('[API]', method, url, 'Took ' + ((new Date()).getTime() - requestStartedAt.getTime()) + 'ms.');

                return response;
            })
            .catch((err) => {
                let message;
                let responseJson;

                if (err) {
                    if (err.response && err.response.data) {
                        responseJson = err.response.data;
                    }

                    if (responseJson && responseJson.hasOwnProperty('message')) {
                        message = responseJson.message;
                    } else {
                        message = err.message ? err.message : err.toString();
                    }
                }

                if (!message) {
                    message = 'API Error';
                }

                throw new ApiError(message, err.response, err.request);
            });
    }

    get(endpoint, data) {
        return this.request('GET', endpoint, data);
    }

    post(endpoint, data) {
        return this.request('POST', endpoint, data);
    }

    patch(endpoint, data) {
        return this.request('PATCH', endpoint, data);
    }

    delete(endpoint, data) {
        return this.request('DELETE', endpoint, data);
    }
}
