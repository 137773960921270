/**
 * @param {string} type
 * @param {string} text
 * @param {string} [html]
 */
export const createAlert = (type, text, html) => {
    const alert = document.createElement('div');
    alert.classList.add('alert', `alert--${type}`);
    if (html) {
        alert.innerHTML = html;
    } else if (text) {
        alert.innerText = text;
    }
    return alert;
};

/**
 * @param {HTMLElement} el
 */
export const removeAlerts = (el) => {
    for (const alertEl of el.querySelectorAll('.alert')) {
        alertEl.remove();
    }
};
